import React from "react";
import Speeds from "../components/Speeds";
import "../styles/Home.css";
import Chart from "../components/Chart";
const Home = () => {
  return (
    <div className="Home pages">
      <Chart />
      {/* <Speeds /> */}
    </div>
  );
};
export default Home;
